import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment/moment";
import { CaretRightIcon, DateIcon, RefreshIcon } from "../../../../assets/Icons";
import { http_get } from "../../../utils/api_client";
import { API_ENDPOINTS } from "../../../utils/endpoints";
import { setDashboardData } from "../../../../../redux/dashboardSlice";
import {fetchProfile, getProfile} from "../../../../../redux/profileSlice";
import $ from 'jquery';

const filterOptions = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "All time", value: "all_time" },
];

const DatePicker = ({ onSelect, selectedDate }) => {
  const inputRef = useRef(null);

  // useEffect(() => {
  //   const datePickerElement = $(inputRef.current);
  //
  //   datePickerElement.datepick({
  //     onSelect: (dateText) => onSelect(new Date(dateText[0]).toISOString()),
  //     dateFormat: 'dd-mm-yyyy',
  //     defaultDate: selectedDate ? new Date(selectedDate) : null,
  //     maxDate: new Date(),
  //   });
  //
  //   return () => datePickerElement.datepick('destroy');
  // }, []);

  useEffect(() => {
    if (selectedDate) {
      $(inputRef.current).datepick('setDate', new Date(selectedDate));
    }
  }, [selectedDate]);

  return (
    <input
      type="text"
      className={`input-calendar`}
      value={selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : ''}
      ref={inputRef}
      onChange={() => { }}
    />
  );
}

const DashboardToolbar = () => {
  const [userName, setUserName] = useState('');
  const [activeFilter, setActiveFilter] = useState('all_time');
  const [dates, setDates] = useState({ startDate: '', endDate: '' });
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getProfile(state));

  const fetchDashboardData = async () => {
    if (activeFilter === "custom_date" && (!dates.startDate || !dates.endDate)) {
      alert("Please add start date and end date inputs.");
      return;
    }
    try {
      const response = await http_get(API_ENDPOINTS.GET_DASHBOARD_DATA, {
        option: activeFilter,
        custom_date: {
          start_date: dates.startDate ? moment(dates.startDate).format('DD-MM-YYYY') : '',
          end_date: dates.endDate ? moment(dates.endDate).format('DD-MM-YYYY') : '',
        }
      });
      if (response.status === 200) {
        dispatch(setDashboardData({ data: response.data, filterType: activeFilter }));
      } else {
        alert("Failed to fetch domain data. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to fetch domain data. Please try again.");
    }
  };

  const handleDateChange = (key) => (date) => {
    setDates((prev) => ({ ...prev, [key]: date }));
  };

  const handleApplyClick = () => {
    fetchDashboardData();
    setIsDropdownActive(false);
  };

  const resetFilters = () => {
    setActiveFilter('all_time');
    setDates({ startDate: '', endDate: '' });
    setIsDropdownActive(false);
  }

  const handleOptionChange = (value) => {
    setActiveFilter(value);
    if (value !== "custom_date") {
      setDates({ startDate: '', endDate: '' });
    }
  }

  useEffect(() => {
    fetchDashboardData();
  }, [activeFilter]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    if (currentUser.first_name && currentUser.last_name) {
      setUserName(currentUser.first_name + ' ' + currentUser.last_name);
    }
  }, [currentUser]);

  return (
    <div className="wrapper-dashboard-toolbar custom-dashboard-toolbar">
      <div className="flex-dashboard-toolbar">
        <div className="item-flex-dashboard-toolbar">
          <h1 className="h2 mb-4 color-black text-uppercase text-weight-bold">Hi {userName} 👋</h1>
          <p className="size-14 text-weight-medium">Here's how your Onboarding is Performing</p>
        </div>
        <div className="item-flex-dashboard-toolbar">
          <div className="dashboard-filter-group">
            <div className="item-dashboard-filter-group">
              <div className="dashboard-filter-wmya">
                {filterOptions.map(({ label, value }) => (
                  <div key={value} onClick={() => handleOptionChange(value)} className="Item-dashboard-filter-wmya" style={{ cursor: 'pointer' }}>
                    <div className={`btn-filter-wmya ${activeFilter === value ? "active" : ""}`}>{label}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="item-dashboard-filter-group">
              <div className={`cover-dashboard-filter-calendar ${isDropdownActive ? "active" : ""}`}>
                <div onClick={() => setIsDropdownActive((prev) => !prev)} className="dashboard-filter-calendar">
                  <div className="date-dashboard-calendar">
                    <div className="start-date-dashboard-calendar">{dates.startDate ? moment(dates.startDate).format('DD-MM-YYYY') : 'Start Date'}</div>&nbsp; To &nbsp;
                    <div className="end-date-dashboard-calendar">{dates.endDate ? moment(dates.endDate).format('DD-MM-YYYY') : 'End Date'}</div>
                  </div>
                  <div className="calendar-icon-dashboard-filter"><DateIcon /></div>
                </div>
                {/* <!-- Dropdown Datepicker Dashboard--> */}
                <div className="dropdown-datepicker-dashboard" style={{ display: isDropdownActive ? "block" : "none" }}>
                  <div className="datepicker-dashboard">
                    <div className="content-datepicker-dashboard">
                      <div className="options-datepicker-dashboard">
                        {filterOptions.map(({ label, value }) => (
                          <div className="item-options-datepicker" key={value}>
                            <label className="radio-options-datepicker">
                              <input
                                className="option-radio"
                                type="radio"
                                name={label}
                                value={value}
                                checked={activeFilter === value}
                                onChange={() => handleOptionChange(value)}
                              />
                              <span className="text-option-radio">{label}</span>
                            </label>
                          </div>
                        ))}
                        <div className="item-options-datepicker">
                          <button className="custom-range-date" onClick={() => handleOptionChange("custom_date")}>
                            <span className="text-ustom-range-date">
                              Custom date range
                            </span>
                            <span className="icon-custom-range-date">
                              <CaretRightIcon />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="double-datepicker-calendar">
                        <div className="item-double-datepicker-calendar">
                          <div className="cover-datepicker-calendar">
                            <label className="label-datepicker-calendar">Start date</label>
                            <DatePicker
                              onSelect={handleDateChange('startDate')}
                              selectedDate={dates.startDate}
                            />
                          </div>
                        </div>
                        <div className="item-double-datepicker-calendar">
                          <div className="cover-datepicker-calendar">
                            <label className="label-datepicker-calendar">End date</label>
                            <DatePicker
                              onSelect={handleDateChange('endDate')}
                              selectedDate={dates.endDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-datepicker">
                      <div className="item-buttons-datepicker">
                        <button type="button" className="btn-clean-all" onClick={resetFilters}>Clean all</button>
                      </div>
                      <div className="item-buttons-datepicker">
                        <div className="flex-button-datepicker">
                          <button className="border-button-datepicker btn-discard" type="button" onClick={resetFilters}>Discard</button>
                          <button className="button-datepicker btn-apply-datepicker" type="button" onClick={handleApplyClick}>Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Dropdown Datepicker Dashboard--> */}
              </div>
            </div>
            <div className="item-dashboard-filter-group">
              <button className="button-reset-dashboard-filter" type="button" onClick={resetFilters}>
                <RefreshIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardToolbar;
