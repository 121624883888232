import React, { useState } from "react";
import { Provider } from "react-redux";
import { store } from "../../../../redux/store";
import { http_put } from "../../utils/api_client";
import { API_ENDPOINTS } from "../../utils/endpoints";
import FileUploader from "../../../atoms/file-uploader/FileUploader";
import { IMAGE_TYPES } from "../../../../constants/image_types";

const CompanyPage = ({ company }) => {
  const [companyName, setCompanyName] = useState(company.name || '');
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (event) => setCompanyName(event.target.value);

  const updateCompanyName = async () => {
    setIsSaving(true);
    try {
      const response = await http_put(API_ENDPOINTS.PUT_COMPANY, { company_name: companyName.trim() });
      alert(response.status === 200 ? "Company name updated successfully." : 'Failed to save company name. Please try again.');
    }
    catch (error) {
      console.log("Error : ", error);
      alert('Failed to save company name. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <Provider store={store}>
        <div className='workspace-card'>
          <p>Need help with your company name? Contact us at support@usernurture.com</p>
          <h4>Company name</h4>
          <div className='workspace-fields'>
            <input
              className="workspace-input"
              placeholder="company-name"
              type="text"
              name="company_name"
              value={companyName}
              onChange={handleInputChange}
            />
            <button
              className="common-btn"
              type="button"
              onClick={updateCompanyName}
              disabled={isSaving}
            >
              <span>{isSaving ? 'Saving...' : 'Update'}</span>
            </button>
          </div>
        </div>
        <br />
        {[
          { title: "Default Company Logo", name: "logoUrl", value: company.company_logo_url, imageType: IMAGE_TYPES.COMPANY_LOGO },
          { title: "Default Company Favicon", name: "faviconUrl", value: company.favicon_url, imageType: IMAGE_TYPES.FAVICON },
        ].map(({ title, name, value, imageType }) => (
          <>
            <div key={name} className='workspace-card'>
              <h4 className="mb-12">{title}</h4>
              <p>Need help with your {title.toLowerCase()}? Contact us at support@usernurture.com</p>
              <FileUploader
                name={name}
                onChange={() => { }}
                value={value}
                image_type={imageType}
                className="mb-12"
              />
              <div className='plan-card'>
                <h4 className="mb-12">Current Plan</h4>
                <p>{company.plan.name}</p>
              </div>
            </div>
            <br />
          </>
        ))}
      </Provider>
    </>
  )
}

export default CompanyPage;