import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyDetails, getCompanyDetails, setAudience } from '../../../redux/hubSlice';
import EditProfileModal from './EditProfileModal';
import userAvatar from '../../assets/images/user-avatar.png';

const HubLayout = ({ children, headerStyle = {}, hideHeader = false, props }) => {

  const dispatch = useDispatch();
  const audience = props?.audience || props;
  const emailOrName = audience?.first_name ? `${audience.first_name} ${audience.last_name}` : audience?.email;
  const profileImg = audience?.avatar_url || userAvatar;

  useEffect(() => {
    dispatch(fetchCompanyDetails());
    audience && dispatch(setAudience({ ...audience }));
  }, []);

  const company = useSelector((state) => getCompanyDetails(state));

  const navigateTo = (path) => {
    window.location.href = `/${company?.slug}${path}`;
  };

  return (
    <>
      <div className="un-hub">
        {!hideHeader &&
          <div className="un-hub__header" style={headerStyle}>
            <img src={company.logo_url} onClick={() => navigateTo('')} alt="Company Logo" />
            {audience.email ?
              <>
                <div className='signin-detail'>
                  <img src={profileImg} alt="User Avatar" />
                  <button onClick={() => $('#editProfileModal').modal('show')}>{emailOrName} |</button>
                  <button className="un-btn un-btn--primary" style={{ paddingLeft: 5 }} onClick={() => navigateTo('/sign_out')}>
                    Sign out
                  </button>
                </div>
              </>
              :
              <button className="un-btn un-btn--primary signin-btn-class" onClick={() => navigateTo('/passwordless_sign_in')}>
                Sign in as a Learner
              </button>
            }
          </div>
        }
        {children}
      </div >
      <EditProfileModal audience={audience} modalClose={() => $('#editProfileModal').modal('hide')} />
    </>
  );
};

export default HubLayout;
